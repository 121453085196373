import muertech from "./experience/muertech";
import consulting from "./experience/consulting";
import muertechlead from "./experience/muertechlead";
import coism from "./experience/coism";
import alloutdoorgear from "./experience/alloutdoorgear";

const mublockchaintech = {
    position: 'Lead Developer',
    tab: 'muBlockchainTech',
    company: 'muBlockchainTech',
    workStart: 'Dec. 2020',
    workUntil: '<span class="present-work">Present</span>',
    des:
        "<li>Coordinated with 9 stakeholders from former project along with 1 backend, 1 fullstack, and 1 native developer to wrap all properties into mobile app using React Native and Single Sign-On.</li><p><li>Developed continuous integration and continuous development scripts to coordinate automatic 30+ plugin\n" +
        "updates of WordPress properties as well as inserting a staging server to serve as a starting point for QA and\n" +
        "unit testing.</li></p><p><li>Created System of Controls (SoC) including self-hosting of 4 mission-critical applications, databases and\n" +
        "implemented password protocols, multi-factor authentication, access control and encrypted cloud backups.</li></p><p><li>Started working with Solidity consultant to design project guidelines for ERC-20 tokens (to represent stake\n" +
        "in the company) and ERC-721 burnable tokens to be implemented for hyperspecific (unable to disclose)\n" +
        "purpose.</li></p>",
    url: "https://mublockchain.tech"
}

const achievefitnessstudio = {
    position: 'Fitness Manager',
    tab: 'Achieve Fitness',
    company: 'Achieve Fitness Studio',
    workStart: 'Nov. 2016',
    workUntil: 'Nov. 2017',
    des: "<li>Taught clients proper exercise technique and nutrition. Created workout plans and health\n" +
        "recommendations.</li><p><li>Utilized WordPress and SEO to obtain new clients and set sales appointments, with top 3 months of sales\n" +
        "generating over $10,000+ in revenue.</li></p>",
}

export default [mublockchaintech, muertechlead, muertech, consulting, coism, alloutdoorgear, achievefitnessstudio]