export default {
    present: true,
    position: "Lead Developer",
    tab: "Muertech (Lead)",
    company:
        "Muertech",
    workStart: "Jan. 2020",
    workUntil: "Dec. 2020",
    des: 
        "<li>Broke up monolithic CMS architecture into smaller services, including a membership system, leading to\n" +
        "faster performances across MySQL databases and reduced future development time and easier scalability.</li><p><li>Successfully led 3 developers, multiple contracted artists, e-commerce manager, product designer and\n" +
        "copywriter to create gigantic 2,000+ unique e-commerce dropshipping product store in less than 45 days.</li></p><p><li>Used technical SEO tools to audit CMS and E-commerce store, fixing every last error leading to increase in\n" +
        "previous traffic levels of 6,000-8,000 organic visitors per day, leading ultimately to hundreds of new\n" +
        "memberships</li></p><p><li>Implemented custom self-hosted GitLab version control for all projects. Hardened all properties against\n" +
        "remote code execution vulnerabilities, file permission errors, errant API keys and credentials, DDoS attacks\n" +
        "and maintained 99.99%+ uptime for all properties minus one (98.9%).</li></p>",
};
