export default {
    position: 'E-commerce consultant',
    tab: 'E-commerce Consulting',
    company: 'Freelance',
    workStart: 'April 2018',
    workUntil: 'June 2019',
    des: "<li>Helped women’s fashion company implement inventory management software and trained E-Commerce\n" +
        "Manager across multiple sales channels</li><p><li>Devised a plan with B2B linen wholesaler to branch out to B2C. Worked with Sales Manager to hire and\n" +
        "train E-Commerce Manager.</li></p><p><li>Streamlined E-commerce operations for clothing overstock wholesaler. Trained 4 product listers on flat\n" +
        "(.csv) files leading to an almost 50% increase in products listed per day.</li></p>",

}