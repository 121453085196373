export default {
    position: 'Developer',
    tab: 'Muertech',
    company: 'Muertech',
    workStart: 'June 2019',
    workUntil: 'Jan. 2020',
    des:
        "<li>Created custom WooCommerce site for motorcycle parts and restorations with over 1,000 motorcycles and\n" +
        "100,000+ parts sales, implementing custom UI/UX parts checker. Used python to scrape over 8000+ pieces\n" +
        "of unique customer data from previous eBay transactions.</li><p><li>Collaborated with 2 other developers and multiple contractors to expand project multilingual capabilities,\n" +
        "both on JAMStack and WordPress.</li></p><p><li>Delivered headless WordPress + JAMStack (Gatsby) project before agreed upon deadline for small pet\n" +
        "service business, leading to 95+ scores in Google PageInsights and ranking #1 in search engine for brand\n" +
        "name and ’City Pet Service’ keyword.</li></p><p><li>Created Django microservice app to scrape background acting agency job postings and send updates via\n" +
        "Twitter and/or E-mail</li></p>",

}